.modal__opened {
  animation: animation_open 0.23s;
}

.modal__closed::backdrop {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
  backdrop-filter: blur(3px);
  animation: animation_close 0.4;
}

@keyframes animation_open {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

@keyframes animation_close {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

@keyframes animation_modal {
  from {
    transform: translateY(-200px);
  }

  to {
    transform: translateY(2000px);
  }
}

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(1);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* dialog[open] {
    animation: appear .25s cubic-bezier(0, 1.8, 1, 1.8);
  }
  
    dialog::backdrop {
      background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
      backdrop-filter: blur(3px);
    }
    
   
  @keyframes appear {
    from {
      opacity: 0;
      transform: scale(1.06);
    }
  
    to {
      opacity: 1;
      transform: scale(1);
    }
  } 
   */
